import { FC } from 'react';
import IconProps from './IconProps';

const CircleTickIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.44804 11.293C9.05751 10.9024 8.42435 10.9024 8.03382 11.293C7.6433 11.6835 7.6433 12.3167 8.03382 12.7072L9.44804 11.293ZM10.7409 14.0001L10.0338 14.7072C10.2214 14.8947 10.4757 15.0001 10.7409 15.0001C11.0061 15.0001 11.2605 14.8947 11.448 14.7072L10.7409 14.0001ZM15.448 10.7072C15.8386 10.3167 15.8386 9.68349 15.448 9.29297C15.0575 8.90244 14.4243 8.90244 14.0338 9.29297L15.448 10.7072ZM8.03382 12.7072L10.0338 14.7072L11.448 13.293L9.44804 11.293L8.03382 12.7072ZM11.448 14.7072L15.448 10.7072L14.0338 9.29297L10.0338 13.293L11.448 14.7072ZM19.7432 12C19.7432 16.4183 16.1615 20 11.7432 20V22C17.266 22 21.7432 17.5229 21.7432 12H19.7432ZM11.7432 20C7.32489 20 3.74316 16.4183 3.74316 12H1.74316C1.74316 17.5229 6.22032 22 11.7432 22V20ZM3.74316 12C3.74316 7.58172 7.32489 4 11.7432 4V2C6.22032 2 1.74316 6.47716 1.74316 12H3.74316ZM11.7432 4C16.1615 4 19.7432 7.58172 19.7432 12H21.7432C21.7432 6.47716 17.2661 2 11.7432 2V4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleTickIcon;
